<template>
  <div id="xgVideos">
  </div>
</template>

<script>
import Player from 'xgplayer';

export default {
  name: 'xgVideos',
  data() {
    return {
      videoUrls: "",
    }
  },  
  props:['videoUrl'],
  mounted() {
    let player = new Player({
      id: 'xgVideos',
      url: this.videoUrl,
      fitVideoSize: 'auto',
      videoInit: true,
      playbackRate: [0.5, 0.75, 1, 1.5, 2],
      defaultPlaybackRate: 1,
      'x5-video-orientation': 'portraint',
      playsinline: false,
      fluid: true,
    });
    const state = document.querySelector('video');
    state.setAttribute('playsinline','');
    state.setAttribute('preload','auto');
  },
}
</script>

<style>
  #xgVideos {max-width: 100%;margin: 0 auto}
  .xgplayer-icon-play svg{opacity: 0}
  .xgplayer-skin-default .xgplayer-start {background: none!important; overflow: inherit!important;}
  .xgplayer-skin-default .xgplayer-start:hover {opacity: 1;}
  .xgplayer-icon-play {background: url("~@/assets/PC/fenceDetail/player.png") no-repeat;background-size: 100%;}
  .xgplayer-skin-default .xgplayer-progress-played {background-image:linear-gradient(-90deg,#851ffa,#6606e3)}
  .xgplayer-skin-default .xgplayer-drag {background: #851ffa;}
  .xgplayer-skin-default video {width: auto; max-width: 100%;}
</style>